












import { Component, Prop, Vue } from "vue-property-decorator";
import ApiService from "@/api/api.service";
import { Role } from "@/types/Role";

@Component({
  components: {}
})
export default class ChangeRoleUser extends Vue {
  @Prop() id!: number;
  @Prop() roles!: Role[];

  loading = false;
  rolesAll = [];
  selectRole = "none";

  mounted() {
    this.loadRoles();
    if (typeof this.roles !== "undefined" && this.roles.length > 0) {
      this.selectRole = this.roles[0].name;
    }
  }

  loadRoles() {
    this.loading = true;
    ApiService.get("api/admin/roles").then(res => {
      this.rolesAll = res.data;
      this.loading = false;
    });
  }

  updateUser() {
    this.loading = true;
    ApiService.post("api/admin/roles/assign", {
      user: this.id,
      role: this.selectRole
    }).then(() => {
      this.openNotificationWithIcon();
      this.loading = false;
    });
  }

  handleChange() {
    this.updateUser();
  }

  openNotificationWithIcon() {
    this.$notification.success({
      message: "Updated",
      description: "User role updated"
    });
  }
}
