































































import { Component, Prop, Vue } from "vue-property-decorator";
import ChangeRoleUser from "@/components/Admin/ChangeRoleUser.vue";
import { loadUser, updateUserStatus } from "@/api/users";
import { User } from "@/types/User";
import { convertMoney } from "@/common/helper";

@Component({
  components: {
    ChangeRoleUser
  }
})
export default class UserPage extends Vue {
  @Prop() private id!: number;

  title = "User";
  user: User = {
    email: ""
  };
  loading = true;

  convertMoney = convertMoney;

  mounted() {
    if (typeof this.id !== "undefined") {
      this.loadUser();
    }
  }

  async loadUser() {
    const { data } = await loadUser(this.id);
    this.user = data;
    this.title = `User ${this.user.email}`;
    this.loading = false;
  }

  async userStatusChange(id: number, status: string) {
    await updateUserStatus(id, status);
    await this.loadUser();
  }

  colorTag = (status: boolean) => (status ? "green" : "red");
}
